
/* @font-face {
    font-family: 'Ownglyph_StudyHard-Rg';
    src: url('./assets/Ownglyph_StudyHard-Rg.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
} */

@font-face {
    font-family: 'LeeSeoyun';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/LeeSeoyun.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


.wrap {
    margin: 5px;
    width: 80%;
    max-width: 600px;
}

.wrap button {
    /* 텍스트 선택 방지 */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* 표준 구문 */

    /* 터치 하이라이트 제거 */
    /* -webkit-tap-highlight-color: transparent; */

    /* 컨텍스트 메뉴(길게 누르기) 방지 */
    -webkit-touch-callout: none;

    /* background-color: rgb(222, 143, 46); */
    width: 100%;
    padding: 20px;
    font-size: 1.6rem;

    font-family: 'LeeSeoyun', sans-serif;
    word-break: keep-all;

    border-radius: 5px;
}
